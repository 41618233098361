import React, { useEffect, useState, useRef } from "react";

import { useAuth } from "@/util/use-auth";

const MainstayDashboardPage = () => {
  const { authUser } = useAuth();

  return <></>;
};

export default MainstayDashboardPage;
