import React, { useEffect, useState, useRef } from "react";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  createStyles,
  Group,
  Loader,
  Modal,
  Paper,
  SimpleGrid,
  Skeleton,
  Tabs,
  Text,
} from "@mantine/core";
import { TbList, TbChartBar } from "react-icons/tb";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import { httpClient } from "@/util/Api";
import { useAuth } from "@/util/use-auth";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = createStyles((theme) => ({
  tabs: {
    fontSize: 16,
  },
}));

const CandidateDashboardPage = () => {
  const { classes } = useStyles();
  const { authUser } = useAuth();

  const isInitialMount = useRef(true);

  const [countM_Hh_in_Hm, setCountM_Hh_in_Hm] = useState([]);
  const [countM_Hh_in_HmLoading, setCountM_Hh_in_HmLoading] = useState(true);
  const [countHh_in_M, setCountHh_in_M] = useState([]);
  const [countHh_in_MLoading, setCountHh_in_MLoading] = useState(true);
  const [countM_Hh_in_Orportor, setCountM_Hh_in_Orportor] = useState([]);
  const [countM_Hh_in_OrportorAllModal, setCountM_Hh_in_OrportorAllModal] =
    useState(false);

  const [countM_Hh_in_OrportorLoading, setCountM_Hh_in_OrportorLoading] =
    useState(true);
  const [countM_Hh_in_Subdist, setCountM_Hh_in_Subdist] = useState([]);
  const [countM_Hh_in_SubdistLoading, setCountM_Hh_in_SubdistLoading] =
    useState(true);
  const [countM_Hh_in_Dist, setCountM_Hh_in_Dist] = useState([]);

  const [countM_Hh_in_DistLoading, setCountM_Hh_in_DistLoading] =
    useState(true);
  const [countHm_M_Hh, setCountHm_M_Hh] = useState({ Hm: 0, M: 0, Hh: 0 });
  const [countHm_M_HhLoading, setCountHm_M_HhLoading] = useState(true);

  const chartData1 = {
    labels: countM_Hh_in_Hm.map((data) => data.headmainstay_data.firstname),
    datasets: [
      {
        label: "จำนวนทีมทำ",
        data: countM_Hh_in_Hm.map((data) => data.count_m),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "จำนวนผู้มีสิทธิ์",
        data: countM_Hh_in_Hm.map((data) => data.count_hh),
        backgroundColor: "rgba(254, 168, 221, 0.5)",
      },
    ],
  };

  const chartData2 = {
    labels: countM_Hh_in_Orportor.map((data) => data._id),
    datasets: [
      {
        label: "จำนวนทีมทำ",
        data: countM_Hh_in_Orportor.map((data) => data.count_m),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "จำนวนผู้มีสิทธิ์",
        data: countM_Hh_in_Orportor.map((data) => data.count_hh),
        backgroundColor: "rgba(254, 168, 221, 0.5)",
      },
    ],
  };

  const chartData3 = {
    labels: countM_Hh_in_Subdist.map((data) => data._id),
    datasets: [
      {
        label: "จำนวนทีมทำ",
        data: countM_Hh_in_Subdist.map((data) => data.count_m),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "จำนวนผู้มีสิทธิ์",
        data: countM_Hh_in_Subdist.map((data) => data.count_hh),
        backgroundColor: "rgba(254, 168, 221, 0.5)",
      },
    ],
  };

  const chartData4 = {
    labels: countM_Hh_in_Dist.map((data) => data._id),
    datasets: [
      {
        label: "จำนวนทีมทำ",
        data: countM_Hh_in_Dist.map((data) => data.count_m),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "จำนวนผู้มีสิทธิ์",
        data: countM_Hh_in_Dist.map((data) => data.count_hh),
        backgroundColor: "rgba(254, 168, 221, 0.5)",
      },
    ],
  };

  const getCountM_Hh_in_HmData = () => {
    httpClient
      .get("/dashboard/count/M_Hh_in_Hm/" + authUser.cid, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.data != null) {
          setCountM_Hh_in_Hm(res.data.data);
          setCountM_Hh_in_HmLoading(false);
        } else {
        }
      })
      .catch((err) => {});
  };

  const getCountHh_in_MData = () => {
    httpClient
      .get("/dashboard/count/Hh_in_M/" + authUser.cid, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.data != null) {
          setCountHh_in_M(res.data.data);
          setCountHh_in_MLoading(false);
        } else {
        }
      })
      .catch((err) => {});
  };

  const getCountM_Hh_in_OrportorData = () => {
    httpClient
      .get("/dashboard/count/M_Hh_in_Orportor/" + authUser.cid, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.data != null) {
          setCountM_Hh_in_Orportor(res.data.data);
          setCountM_Hh_in_OrportorLoading(false);
        } else {
        }
      })
      .catch((err) => {});
  };

  const getCountM_Hh_in_SubdistData = async () => {
    var array1;
    var array2;

    await httpClient
      .get("/dashboard/count/M_in_Subdistrict/" + authUser.cid, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.data != null) {
          // setCountM_Hh_in_Subdist(res.data.data);
          // setCountM_Hh_in_SubdistLoading(false);
          array1 = res.data.data;
        } else {
        }
      })
      .catch((err) => {});

    await httpClient
      .get("/dashboard/count/Hh_in_Subdistrict/" + authUser.cid, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.data != null) {
          // setCountM_Hh_in_Subdist(res.data.data);
          // setCountM_Hh_in_SubdistLoading(false);
          array2 = res.data.data;
        } else {
        }
      })
      .catch((err) => {});

    const array3 = [...array1, ...array2];

    let array4 = [];
    array3.forEach((a) => (array4[a._id] = { ...array4[a._id], ...a }));
    array4 = Object.values(array4);
    setCountM_Hh_in_SubdistLoading(false);
    setCountM_Hh_in_Subdist(array4);
  };

  const getCountM_Hh_in_DistData = async () => {
    var array1;
    var array2;

    await httpClient
      .get("/dashboard/count/M_in_District/" + authUser.cid, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.data != null) {
          array1 = res.data.data;
        } else {
        }
      })
      .catch((err) => {});

    await httpClient
      .get("/dashboard/count/Hh_in_District/" + authUser.cid, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.data != null) {
          array2 = res.data.data;
        } else {
        }
      })
      .catch((err) => {});

    const array3 = [...array1, ...array2];

    let array4 = [];
    array3.forEach((a) => (array4[a._id] = { ...array4[a._id], ...a }));
    array4 = Object.values(array4);
    setCountM_Hh_in_DistLoading(false);
    setCountM_Hh_in_Dist(array4);
  };

  const getCountHm_M_HhData = () => {
    httpClient
      .get("/dashboard/count/Hm/" + authUser.cid, {
        withCredentials: true,
      })
      .then((res) => {
        if (typeof res.data.data !== "undefined" && res.data.data.length > 0) {
          // setCountHm_M_Hh({ ...countHm_M_Hh, Hm: res.data.data[0].count });
          setCountHm_M_Hh((prevState) => ({
            ...prevState,
            Hm: res.data.data[0].count,
          }));
        } else {
        }
      })
      .catch((err) => {});

    httpClient
      .get("/dashboard/count/M/" + authUser.cid, {
        withCredentials: true,
      })
      .then((res) => {
        if (typeof res.data.data !== "undefined" && res.data.data.length > 0) {
          setCountHm_M_Hh((prevState) => ({
            ...prevState,
            M: res.data.data[0].count,
          }));
        } else {
        }
      })
      .catch((err) => {});

    httpClient
      .get("/dashboard/count/Hh/" + authUser.cid, {
        withCredentials: true,
      })
      .then((res) => {
        if (typeof res.data.data !== "undefined" && res.data.data.length > 0) {
          setCountHm_M_Hh((prevState) => ({
            ...prevState,
            Hh: res.data.data[0].count,
          }));
        } else {
        }
      })
      .catch((err) => {});

    setCountHm_M_HhLoading(false);
  };

  useEffect(() => {
    if (
      authUser.role === "candidate" ||
      (authUser !== "" && authUser.permission.dasPerm.includes(1))
    ) {
      getCountM_Hh_in_HmData();
      getCountHh_in_MData();
      getCountM_Hh_in_OrportorData();
      getCountHm_M_HhData();
      getCountM_Hh_in_SubdistData();
      getCountM_Hh_in_DistData();
    } else {
    }
  }, []);

  return (
    <>
      {authUser.role === "candidate" ||
      (authUser !== "" && authUser.permission.dasPerm.includes(1)) ? (
        <>
          <SimpleGrid
            cols={3}
            breakpoints={[
              { maxWidth: "md", cols: 2 },
              { maxWidth: "xs", cols: 1 },
            ]}
          >
            <Paper withBorder p="md" radius="md">
              <Group position="apart">
                <Text size="md" color="dimmed">
                  จำนวนทีมนำ
                </Text>
              </Group>
              <Group align="flex-end" spacing="xs" mt={25}>
                <Text weight={700} size="lg" color="green.8">
                  {countHm_M_Hh.Hm} คน
                </Text>
              </Group>
            </Paper>
            <Paper withBorder p="md" radius="md">
              <Group position="apart">
                <Text size="md" color="dimmed">
                  จำนวนทีมทำ
                </Text>
              </Group>
              <Group align="flex-end" spacing="xs" mt={25}>
                <Text weight={700} size="lg" color="green.8">
                  {countHm_M_Hh.M} คน
                </Text>
              </Group>
            </Paper>
            <Paper withBorder p="md" radius="md">
              <Group position="apart">
                <Text size="md" color="dimmed">
                  จำนวนผู้มีสิทธิ์
                </Text>
              </Group>
              <Group align="flex-end" spacing="xs" mt={25}>
                <Text weight={700} size="lg" color="green.8">
                  {countHm_M_Hh.Hh} คน
                </Text>
              </Group>
            </Paper>
          </SimpleGrid>
          <Tabs defaultValue="list" mt={10}>
            <Tabs.List>
              <Tabs.Tab
                value="list"
                icon={<TbList size={14} />}
                className={classes.tabs}
              >
                แบบรายการ
              </Tabs.Tab>
              <Tabs.Tab
                value="graph"
                icon={<TbChartBar size={14} />}
                className={classes.tabs}
              >
                แบบกราฟ
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="list" pt="xs">
              <SimpleGrid
                cols={2}
                breakpoints={[
                  { maxWidth: "sm", cols: 2 },
                  { maxWidth: "xs", cols: 1 },
                ]}
              >
                <Card shadow="sm" p="lg" radius="md" withBorder>
                  <Card.Section withBorder inheritPadding py="sm">
                    <Text weight={500}>ทีมนำที่มีทีมทำเยอะที่สุด</Text>
                  </Card.Section>
                  <Group position="apart" mt="md" mb="xs">
                    <Text weight={500}>ทีมนำ</Text>
                    <Text weight={500}>จำนวนทีมทำ</Text>
                  </Group>
                  {countM_Hh_in_Hm.slice(0, 5).map((data, index) => (
                    <Group position="apart" mt="md" mb="xs" key={index}>
                      <Text>
                        {index + 1}. {data.headmainstay_data.firstname}
                      </Text>
                      <Text>{data.count_m} คน</Text>
                    </Group>
                  ))}
                </Card>

                <Card shadow="sm" p="lg" radius="md" withBorder>
                  <Card.Section withBorder inheritPadding py="sm">
                    <Text weight={500}>ทีมทำที่มีผู้มีสิทธิ์เยอะที่สุด</Text>
                  </Card.Section>
                  <Group position="apart" mt="md" mb="xs">
                    <Text weight={500}>ทีมทำ</Text>
                    <Text weight={500}>จำนวนผู้มีสิทธิ์</Text>
                  </Group>
                  {countHh_in_M.slice(0, 5).map((data, index) => (
                    <Group position="apart" mt="md" mb="xs" key={index}>
                      <Text>
                        {index + 1}. {data.nametitle}
                        {data.firstname} {data.lastname}
                      </Text>
                      <Text>{data.sum_househeaders} คน</Text>
                    </Group>
                  ))}
                </Card>
              </SimpleGrid>
              <SimpleGrid
                mt={10}
                cols={3}
                breakpoints={[
                  { maxWidth: "sm", cols: 2 },
                  { maxWidth: "xs", cols: 1 },
                ]}
              >
                <Card shadow="sm" p="lg" radius="md" withBorder>
                  <Card.Section withBorder inheritPadding py="sm">
                    <Group position="apart">
                      <Text weight={500}>อปท.ที่มีทีมทำเยอะที่สุด</Text>
                      <Button
                        variant="default"
                        onClick={() => setCountM_Hh_in_OrportorAllModal(true)}
                      >
                        ดูทั้งหมด
                      </Button>
                    </Group>
                  </Card.Section>
                  <Group position="apart" mt="md" mb="xs">
                    <Text weight={500}>อปท.</Text>
                    <Text weight={500}>จำนวนทีมทำ</Text>
                  </Group>
                  {countM_Hh_in_Orportor.slice(0, 5).map((data, index) => (
                    <Group position="apart" mt="md" mb="xs" key={index}>
                      <Text>
                        {index + 1}. {data._id}
                      </Text>
                      <Text>{data.count_m} คน</Text>
                    </Group>
                  ))}
                </Card>
                <Card shadow="sm" p="lg" radius="md" withBorder>
                  <Card.Section withBorder inheritPadding py="sm">
                    <Text weight={500}>ตำบลที่มีทีมทำเยอะที่สุด</Text>
                  </Card.Section>
                  <Group position="apart" mt="md" mb="xs">
                    <Text weight={500}>ตำบล</Text>
                    <Text weight={500}>จำนวนทีมทำ</Text>
                  </Group>
                  {countM_Hh_in_Subdist.slice(0, 5).map((data, index) => (
                    <Group position="apart" mt="md" mb="xs" key={index}>
                      <Text>
                        {index + 1}. {data._id}
                        {data.firstname} {data.lastname}
                      </Text>
                      <Text>{data.count_m} คน</Text>
                    </Group>
                  ))}
                </Card>
                <Card shadow="sm" p="lg" radius="md" withBorder>
                  <Card.Section withBorder inheritPadding py="sm">
                    <Text weight={500}>อำเภอที่มีทีมทำเยอะที่สุด</Text>
                  </Card.Section>
                  <Group position="apart" mt="md" mb="xs">
                    <Text weight={500}>อำเภอ</Text>
                    <Text weight={500}>จำนวนทีมทำ</Text>
                  </Group>
                  {countM_Hh_in_Dist.slice(0, 5).map((data, index) => (
                    <Group position="apart" mt="md" mb="xs" key={index}>
                      <Text>
                        {index + 1}. {data._id}
                        {data.firstname} {data.lastname}
                      </Text>
                      <Text>{data.count_m} คน</Text>
                    </Group>
                  ))}
                </Card>
              </SimpleGrid>
            </Tabs.Panel>
            <Tabs.Panel value="graph" pt="xs">
              <Card shadow="sm" p="lg" radius="md" withBorder>
                <Card.Section withBorder inheritPadding py="sm">
                  <Text weight={500}>จำนวนทีมทำ/ผู้มีสิทธิ์ของแต่ละทีมนำ</Text>
                </Card.Section>
                <Bar data={chartData1} />
              </Card>{" "}
              <Card shadow="sm" p="lg" radius="md" withBorder>
                <Card.Section withBorder inheritPadding py="sm">
                  <Text weight={500}>จำนวนทีมทำ/ผู้มีสิทธิ์ของแต่ละอปท.</Text>
                </Card.Section>
                <Bar data={chartData2} />
              </Card>{" "}
              <Card shadow="sm" p="lg" radius="md" withBorder>
                <Card.Section withBorder inheritPadding py="sm">
                  <Text weight={500}>จำนวนทีมทำ/ผู้มีสิทธิ์ของแต่ละตำบล</Text>
                </Card.Section>
                <Bar data={chartData3} />
              </Card>{" "}
              <Card shadow="sm" p="lg" radius="md" withBorder>
                <Card.Section withBorder inheritPadding py="sm">
                  <Text weight={500}>จำนวนทีมทำ/ผู้มีสิทธิ์ของแต่ละอำเภอ</Text>
                </Card.Section>
                <Bar data={chartData4} />
              </Card>
            </Tabs.Panel>
          </Tabs>
          <Modal
            opened={countM_Hh_in_OrportorAllModal}
            onClose={() => setCountM_Hh_in_OrportorAllModal(false)}
            title="อปท.ที่มีทีมทำเยอะที่สุด"
          >
            <Group position="apart" mt="md" mb="xs">
              <Text weight={500}>อปท.</Text>
              <Text weight={500}>จำนวนทีมทำ</Text>
            </Group>
            {countM_Hh_in_Orportor.map((data, index) => (
              <Group position="apart" mt="md" mb="xs" key={index}>
                <Text>
                  {index + 1}. {data._id}
                </Text>
                <Text>{data.count_m} คน</Text>
              </Group>
            ))}
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default CandidateDashboardPage;
