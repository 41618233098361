import React from "react";
import asyncComponent from "@/util/asyncComponent";
import Head from "next/head";

import Dashboard from "@/routes/dashboard";

const DashboardPage = () => {
  return (
    <React.Fragment>
      <Head>
        <title>Dashboard</title>
      </Head>
      <Dashboard />
    </React.Fragment>
  );
};

export default DashboardPage;
