import React, { useEffect, useState, useRef } from "react";

import { useAuth } from "@/util/use-auth";
import CandidateDashboardPage from "./Candidate";
import MainstayDashboardPage from "./Mainstay";

const DashboardPage = () => {
  const { authUser } = useAuth();

  return (
    <>
      {authUser.role === "candidate" || authUser.role === "calias" ? (
        <CandidateDashboardPage />
      ) : authUser.role === "mainstay" ? (
        <MainstayDashboardPage />
      ) : null}
    </>
  );
};

export default DashboardPage;
